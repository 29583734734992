import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"max-width":"900"},on:{"click:outside":_vm.closeNotesModal},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5 mb-3 d-flex justify-space-between"},[_vm._v(" "+_vm._s(_vm.$t('Nota'))+" "),_c(VBtn,{attrs:{"icon":"","color":"tertiary","aria-label":"close-modal"},on:{"click":_vm.closeNotesModal}},[_c(VIcon,[_vm._v(_vm._s(_vm.icons.mdiClose))])],1)],1),_c(VCardText,[_c('div',{staticClass:"d-flex"},[_c(VTextarea,{ref:"comment_textarea",staticClass:"me-6",attrs:{"rows":"3","outlined":"","counter":"1000"},model:{value:(_vm.note.note),callback:function ($$v) {_vm.$set(_vm.note, "note", $$v)},expression:"note.note"}})],1)]),_c(VDivider),_c(VCardActions,{staticClass:" justify-space-between"},[(_vm.note.id)?_c(VMenu,{attrs:{"offset-x":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"icon-with-label",attrs:{"color":"error","text":""}},'v-btn',attrs,false),on),[_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.$t('Eliminar'))+" ")]),_c(VIcon,{attrs:{"size":"1.5rem"}},[_vm._v(" "+_vm._s(_vm.icons.mdiTrashCanOutline)+" ")])],1)])]}}],null,false,1211204533)},[_c(VCard,{attrs:{"color":_vm.$vuetify.theme.dark ? '#373258' : '#f6faff',"elevation":"8"}},[_c(VCardSubtitle,[_c('b',[_vm._v(" "+_vm._s(_vm.$t('¿Estás seguro de eliminar esta nota') + '?'))])]),_c(VCardText,{staticClass:"text-center"},[_c(VBtn,{staticClass:"me-3",attrs:{"small":"","outlined":"","color":"tertiary"}},[_vm._v(" "+_vm._s(_vm.$t('Cancelar'))+" ")]),_c(VBtn,{attrs:{"small":"","color":"error","outlined":_vm.$vuetify.theme.dark},on:{"click":function($event){return _vm.deleteNote(_vm.note.id)}}},[_vm._v(" "+_vm._s(_vm.$t('Eliminar'))+" ")])],1)],1)],1):_vm._e(),_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){return _vm.handleNotes()}}},[_c(VIcon,{staticClass:"me-2"},[_vm._v(_vm._s(_vm.icons.mdiCommentMultipleOutline))]),_vm._v(" "+_vm._s(_vm.hasNote ? _vm.$t('Guardar') : _vm.$t('Agregar'))+" ")],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }