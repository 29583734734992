const instrumentsParserMixin = {
  computed: {
    schoolCategoryParser() {
        const parser = {
          1: this.$t('Conocimiento sobre el estudiante'),
          2: this.$t('Planificación actividades y contenidos'),
          3: this.$t('Organización del entorno educativo'),
          4: this.$t('Gestión escolar'),
          5: this.$t('Sobre los profesionales'),
          6: this.$t('Sobre la participación de los estudiantes'),
          7: this.$t('Sobre la familia'),
        }
        return parser
    },
      preschoolCategoryParser() {
        const parser = {
          1: this.$t('Información del niño/niña'),
          2: this.$t('Programa en educación temprana: planificación y actividades'),
          3: this.$t('Organización del entorno'),
          4: this.$t('Gestión del servicio'),
          5: this.$t('Prácticas profesionales'),
          6: this.$t('Interacciones de calidad entre niño/niña y adulto'),
          7: this.$t('Participación de la familia'),
        }
        return parser
    },
    schoolIndicatorParser() {
      const parser = {
        '1.1': this.$t('El equipo de profesionales trabaja de forma apropiada con el/la estudiante, en un proceso de valoración colaborativa y coordinada, demostrando estar formado acerca del impacto de la discapacidad múltiple y/o sordoceguera e interactuando comunicativamente de forma apropiada.'),
        '1.2': this.$t('Las evaluaciones sensoriales funcionales (visión y audición) se completan en cada niño.'),
        '1.3': this.$t('El personal del programa coordina o facilita evaluaciones de visión y audición con especialistas cualificados en atención ocular o auditiva (oftalmólogo, optometrista, otolaringólogo, etc.).'),
        '1.4': this.$t('Las evaluaciones se realizan durante rutinas naturales permitiendo observar las interacciones espontáneas del niño.'),
        '1.5': this.$t('Los resultados de la evaluación son revisados periódicamente y se utilizan de forma actualizada en la planificación y en el seguimiento.'),
        '2.1': this.$t('Las planificaciones diarias, las actividades y las estrategias de enseñanza están claramente relacionadas con las metas, los objetivos y los intereses de los estudiantes incluidos en el PEI.'),
        '2.2': this.$t('Las actividades del aula incluyen áreas del currículo expandido y están alineadas con el currículo nacional.'),
        '2.3': this.$t('Las actividades y contenidos se proponen a través de situaciones problemáticas que motivan al estudiante a participar, compartir y hacer elecciones.'),
        '2.4': this.$t('Hay tiempo suficiente para las transiciones entre actividades que respeten las habilidades individuales y permitan la comprensión y participación tranquila de los estudiantes.'),
        '3.1': this.$t('TIEMPO: El día se organiza de manera fluida, conectando las actividades entre sí (por ejemplo, escribir una lista de artículos que se deben comprar para una actividad de cocina, ir a la tienda a comprarlos, completar la actividad de cocina, escribir sobre ella en un diario).'),
        '3.2': this.$t('Hay un horario claramente definido, consistente y accesible para los estudiantes.'),
        '3.3': this.$t('CONTEXTO: El espacio y las áreas de trabajo, son seguros y adaptados a las necesidades de apoyo derivadas de la discapacidad (por ej: para la discapacidad visuales: iluminación, contraste, espacios definidos, etc.; para la pérdida de audición, revestimiento del suelo y de las ventanas para reducir el ruido).'),
        '3.4': this.$t('Se controla el umbral sensorial en el entorno para fomentar la atención y el máximo acceso al aprendizaje.'),
        '3.5': this.$t('Se utilizan múltiples ubicaciones en la escuela y dentro de la comunidad para fomentar la enseñanza en entornos naturales.'),
        '3.6': this.$t('Los materiales didácticos son apropiados para las edades y los niveles de desarrollo de los estudiantes y son accesibles para su uso en el aula.'),
        '3.7': this.$t('Los miembros del personal demuestran saber cómo ayudar a los estudiantes anticipando y hablando sobre las actividades. Por ejemplo, usando calendarios, proporcionando un nivel apropiado de asistencia y cantidad adecuada de pistas para completar una tarea.'),
        '4.1': this.$t('Hay suficiente personal docente representando a los diferentes niveles de gestión para atender las necesidades de aprendizaje de cada niño.'),
        '4.2': this.$t('Hay servicios de apoyo organizados para atender las necesidades educativas del alumno (fisioterapia, orientación y movilidad, etc.).'),
        '4.3': this.$t('El personal recibe supervisión y apoyo de forma organizada por alguien con conocimientos y habilidades en la educación de niños con DM/DV/SC.'),
        '4.4': this.$t('Hay oportunidades planificadas para que todo el personal, incluidos los padres, equipo de paraprofesionales y administradores participen en actividades de formación e intercambio de información sobre cómo se trabaja y da respuesta a las necesidades de estudiantes con SC/DM/DS.'),
        '4.5': this.$t('Hay un sistema eficaz de comunicación entre el hogar y la escuela (visitas al hogar, libros de comunicación, etc.) para que el personal y las familias puedan mantenerse bien informados sobre el alumno.'),
        '4.6': this.$t('Los administradores del programa interactúan con los funcionarios a nivel local, regional y nacional que son responsables de la supervisión del programa.'),
        '4.7': this.$t('Los Planes Educativos Individualizados (PEI) están documentados y tienen un formato específico para su registro.'),
        '5.1': this.$t('FORMACIÓN EN COMUNICACIÓN: El personal entiende, reconoce y responde a los modos de comunicación más naturales de sus estudiantes, mostrando una relación cómoda y mutuamente agradable.'),
        '5.2': this.$t('El personal es capaz de modelar el siguiente nivel superior de comunicación fomentando el desarrollo de la comunicación de sus estudiantes.'),
        '5.3': this.$t('El personal entiende la intención comunicativa de los comportamientos desafiantes en los estudiantes y responde de forma adecuada.'),
        '5.4': this.$t('TRABAJO EN EQUIPO: Los profesores y administradores se comunican claramente entre sí.'),
        '5.5': this.$t('El personal trabaja cooperativamente desde sus roles disciplinarios y comparten responsabilidades para el desarrollo de planes educativos.'),
        '6.1': this.$t('Los estudiantes tienen acceso a los apoyos comunicativos durante todo el día.'),
        '6.2': this.$t('Cada estudiante participa plenamente en todas las actividades, independientemente de su nivel de desarrollo.'),
        '6.3': this.$t('El estudiante se manifiesta motivado, atento e involucrado en la actividad que se le propone.'),
        '6.4': this.$t('El estudiante reconoce y es reconocido por el resto de sus compañeros.'),
        '6.5': this.$t('Se comparte un sistema comunicativo en común entre los compañeros promoviendo sostener interacciones y lazos de amistad.'),
        '6.6': this.$t('El estudiante usa los diferentes niveles de apoyo disponibles para participar.'),
        '6.7': this.$t('Los estudiantes colaboran entre sí.'),
        '7.1': this.$t('Se fomenta que los miembros de la familia compartan información y expresen sus expectativas durante el proceso de evaluación, generando una comunicación fluida y cercana, y permitiendo una toma de decisiones equitativa y cooperativa.'),
        '7.2': this.$t('Hay actividades coordinadas que promueven la creación de redes entre las familias.'),
        '7.3': this.$t('Las familias participan activamente en las actividades del programa.'),
        '7.4': this.$t('El personal del programa se reúne con las familias en sus hogares y/o en la escuela de forma regular.'),
        '7.5': this.$t('Se ofrecen talleres de formación para las familias que tienen hijos en el programa, incluyendo apoyo e instrucción en estrategias de comunicación eficaces para su hijo.'),
      }
      return parser
    },
    preschoolIndicatorParser() {
      const parser = {
        '1.1': this.$t('El equipo de profesionales trabaja de forma apropiada con cada niño/a, en un proceso de valoración colaborativa y coordinada, demostrando estar formado acerca del impacto de la discapacidad múltiple y/o sordoceguera e interactuando comunicativamente de forma apropiada.'),
        '1.2': this.$t('Las evaluaciones funcionales (sensoriales y motrices) se completan en cada niño/a.'),
        '1.3': this.$t('El personal del programa de educación temprana, coordina o facilita un trabajo en red sobre evaluaciones con especialistas cualificados en atención ocular o auditiva (oftalmólogo, optometrista, otolaringólogo, etc.).'),
        '1.4': this.$t('Las evaluaciones se realizan durante rutinas naturales permitiendo observar las interacciones espontáneas del niño.'),
        '1.5': this.$t('Los resultados de la evaluación son revisados periódicamente (por ejemplo cada 3/6 meses) y se utilizan de forma actualizada en la planificación y en el seguimiento.'),
        '2.1': this.$t('La organización del programa incentiva el juego (variados y adecuados a la edad) y las interacciones sociales para alcanzar logros en las diferentesáreas de desarrollo.'),
        '2.2': this.$t('Se ofrecen rutinas diarias a través de las cuales el niño/niña puedan anticipar distintas actividades en diferentes espacios'),
        '2.3': this.$t('Se integran las rutinas básicas de alimentación, cuidado e higiene para el desarrollo de habilidades sociales.'),
        '2.4': this.$t('Las actividades proponen el aprendizaje a traves de los diferentes sentidos.'),
        '2.5': this.$t('Los miembros del personal demuestran saber cómo ayudar a los estudiantes proporcionando apropiado nivel de asistencia y promoviendo la independencia y la seguridad para la participación de los niños/niñas.'),
        '3.1': this.$t('TIEMPO: Hay una rutina consistente en la organización de la jornada diaria.'),
        '3.2': this.$t('El día se organiza de manera fluida conectando las actividades.'),
        '3.3': this.$t('CONTEXTO: El espacio y las áreas de trabajo, son seguros y adaptados a las necesidades de apoyo derivadas de la discapacidad (por ej:para la discapacidad visuales: iluminación, contraste, espacios definidos, etc.; para la pérdida de audición, revestimiento del suelo y de las ventanas para reducir el ruido; para las discapacidades físicas accesibilidad en el entorno)'),
        '3.4': this.$t('Se utilizan múltiples ubicaciones en el servicio y dentro de la comunidad para fomentar la enseñanza en entornos naturales y apropiados.'),
        '3.5': this.$t('Los materiales utilizados en las actividades (rotulados, con contraste, texturas agradables, etc) proponen, a partir de su disposición y caracteríticas, la exploración activa y la acción del niño sobre el objeto.'),
        '4.1': this.$t('Hay suficiente personal, representando a los diferentes niveles de gestión, para atender las necesidades de aprendizaje de cada niño.'),
        '4.2': this.$t('El personal recibe supervisión y apoyo de forma organizada por alguien con conocimientos y habilidades en la educación de niños con DM/DV/SC.'),
        '4.3': this.$t('Hay oportunidades planificadas para que toda la comunidad educativa participe en actividades formación e intercambio sobre como trabajar y dar respuesta a las necesidades de los niños/niñas con DM/DV/SC'),
        '4.4': this.$t('Hay un sistema eficaz de comunicación entre el hogar y el servicio (visitas al hogar, libros de comunicación…) para que el personal y las familias puedan mantenerse bien informados sobre el niño/niña'),
        '4.5': this.$t('Los administradores del servicio trabaja en red con organizaciones, ministerios u otros programas para organizar la transición de los niños/niñas a otros servicios.'),
        '4.6': this.$t('Existen planes individualizados documentados con un formato específico en el que participen colaborativamente entre todos los miembros del equipo'),
        '5.1': this.$t('FORMACIÓN EN COMUNICACIÓN: El personal entiende, reconoce y responde a los modos de comunicación más naturales de sus estudiantes, mostrando una relación cómoda y mutuamente agradable.'),
        '5.2': this.$t('El personal brinda oportunidades para que el niño o niña pueda realizar elecciones a lo largo de la jornada.'),
        '5.3': this.$t('El personal brinda oportunidades para que el niño o niña inicie la comunicación como por ejemplo la toma de turno, pedir más.'),
        '5.4': this.$t('TRABAJO EN EQUIPO: Los profesores y administradores se comunican claramente entre sí. Por ejemplo comparten información relacionada de los niños/niñas para trabajar de forma consistente y coordinada'),
        '5.5': this.$t('El personal trabaja cooperativamente desde sus roles disciplinarios y comparten responsabilidades para el desarrollo de planes educativos y para la transición a los futuros servicios preescolares.'),
        '6.1': this.$t('Las interacciones entre niño/niña y adulto son saludables, satisfactorias y en un clima amigable (el adulto y/o figura de apego propone interacciones que afianzan el vínculo destacando los aspectos positivos y alentando a la participación).'),
        '6.2': this.$t('Cada niño/niña participa en todas las actividades, independientemente de su nivel de desarrollo'),
        '6.3': this.$t('El niño/niña reconoce y es reconocido por el resto de sus compañeros.'),
        '6.4': this.$t('Los niños/as comparten un sistema comunicativo en común, promoviendo interacciones y lazos de amistad entre pares.'),
        '6.5': this.$t('Se les propone a los niños y niñas modelos de comunicación apropiados para cada uno/una.'),
        '7.1': this.$t('Se involucra a las familias en la implementación de los planes y en la organización de la transición de los niños/niñas a los nuevos servicios.'),
        '7.2': this.$t('Hay actividades coordinadas que promueven la creación de redes entre las familias.'),
        '7.3': this.$t('Las familias participan activamente en las actividades del programa.'),
        '7.4': this.$t('Las familias implementan en sus casas estrategias y orientaciones brindadas por el programa'),
        '7.5': this.$t('Se ofrecen talleres de formación para las familias que tienen hijos en el programa, incluyendo apoyo e instrucción en estrategias de comunicación eficaces para su hijo.'),
      }
      return parser
    },
  },
}

export default instrumentsParserMixin