<template>
  <div>
    <v-dialog
      v-model="showModal"
      max-width="900"
      @click:outside="closeNotesModal"
    >
      <v-card>
        <v-card-title class="text-h5 mb-3 d-flex justify-space-between">
          {{ $t('Nota') }}
          <v-btn icon color="tertiary" @click="closeNotesModal" aria-label="close-modal">
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div class="d-flex">
            <v-textarea
              v-model="note.note"
              class="me-6"
              ref="comment_textarea"
              rows="3"
              outlined
              counter="1000"
            ></v-textarea>
          </div>
        </v-card-text>
          <v-divider></v-divider>
        <v-card-actions class=" justify-space-between">

            <v-menu offset-x right v-if="note.id">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-on="on" v-bind="attrs"
                  class="icon-with-label"
                  color="error"
                  text
                >
                  <div class="d-flex align-center">
                    <span class="label"> {{ $t('Eliminar') }} </span>
                    <v-icon size="1.5rem">
                      {{ icons.mdiTrashCanOutline }}
                    </v-icon>
                  </div>
                </v-btn>
              </template>
              <v-card :color="$vuetify.theme.dark ? '#373258' : '#f6faff'" elevation="8">
                <v-card-subtitle> <b> {{ $t('¿Estás seguro de eliminar esta nota') + '?' }}</b></v-card-subtitle>
                <v-card-text class="text-center">

                  <v-btn
                    small
                    outlined
                    color="tertiary"
                    class="me-3"
                  >
                    {{ $t('Cancelar') }}
                  </v-btn>
                  <v-btn
                    small
                    color="error"
                    :outlined="$vuetify.theme.dark"
                    @click="deleteNote(note.id)"
                  >
                    {{ $t('Eliminar') }}
                  </v-btn>

                </v-card-text>
              </v-card>
            </v-menu>
            <v-btn color="primary" @click="handleNotes()">
              <v-icon class="me-2">{{ icons.mdiCommentMultipleOutline }}</v-icon>
              {{ hasNote ? $t('Guardar') : $t('Agregar') }}
            </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mdiClose, mdiHelpCircleOutline, mdiPencilOutline, mdiTrashCanOutline, mdiPlus, mdiPrinter, mdiCommentMultipleOutline } from '@mdi/js'
export default {
  data: () => {
    return {
      editing: '',
      note: {},
      showModal: false,
    }
  },
  props: {
    evaluation_id: {
      type: Number,
      required: true
    },
    hasNote: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    openModal() {
      this.hasNote ? this.getNote(this.evaluation_id) : this.resetForm()
      this.showModal = true
    },
    closeNotesModal() {
      this.showModal = false
      this.$nextTick(function () {
        document.documentElement.classList.add('overflow-y-hidden');
      })
    },
    async getNote(evaluation_id) {
      try {
        const response = await this.$api.getNote(evaluation_id)
        this.note.note = response.data.note
        this.note.id = response.data.id
        this.note.evaluation = evaluation_id
        this.$forceUpdate()
        this.$emit("reload-evaluation-note", this.note)
      } catch (error) {
        this.resetForm()
      }
    },
    async handleNotes() {
      const form = {
        evaluation: this.evaluation_id,
        note: this.note.note
      }
      try {
        const response  = this.hasNote
          ?  await this.$api.editNote(this.note.id, this.note)
          :  await this.$api.createNote(form)
        this.getNote(this.evaluation_id)
        this.showModal = false
      } catch (error) {
        console.log(error);
      }
    },
    async deleteNote(note_id) {
      try {
        const response = await this.$api.deleteNote(note_id)
        this.resetForm()
        this.$emit("reload-evaluation-note", null)
      } catch (error) {
        console.log(error);
      }
    },
    resetForm() {
      this.note = {}
    }
  },
  setup () {

    return {
      icons: {
        mdiClose,
        mdiHelpCircleOutline,
        mdiPencilOutline,
        mdiTrashCanOutline,
        mdiPlus,
        mdiPrinter,
        mdiCommentMultipleOutline
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
